import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import img37 from '../assest/img/img37.svg'
import img39 from '../assest/img/img39.svg'
import img40 from '../assest/img/img40.svg'

export default function Features() {
    return (
        <Container id="features">
            <Row className='mt-5'>
                <Col xs={12} md={4} className='text-center text-md-start mb-3 mb-md-0'>
                    <h2>
                        <strong>Yenilikçi <br /> Özellikler</strong>
                    </h2>
                </Col>
                <Col xs={12} md={4} className='text-center mb-3 mb-md-0'>
                    <p style={{color:"#A6A6A6"}}>
                        Mesken yönetimine yeni bir bakış açısı, cüzdan <br /> sistemi entegrasyonuyla birlikte bankalara komisyon <br /> ödemeden sakinlere alışveriş yaptırın!
                    </p>
                </Col>
               
            </Row>
            <Row className='mt-5'>
                <Col xs={12} md={4} className='text-center mb-5'>
                    <img className='mb-4' src={img40} style={{ filter: "grayscale(1)" }}/>
                    <h5 className='mt-4'><strong>İşlem Dökümleri</strong></h5>
                    <p className='mt-2'  style={{color:"#A6A6A6"}}>
                        Sakinler hem kendi işlemlerini hemde <br /> site yönetimi harcamalarını detaylı <br /> olarak görüntüleyebilirler.
                    </p>
                </Col>
                <Col xs={12} md={4} className='text-center mb-5'>
                    <img className='mb-4' src={img37} style={{ filter: "grayscale(1)" }}/>
                    <h5  className='mt-4'><strong>Bildirim Sistemleri</strong></h5>
                    <p className='mt-2'  style={{color:"#A6A6A6"}}>
                        Sakinler talep, arıza, anket ve  <br /> rezervasyon işlemlerini sistem  <br /> üzerinden yapabilirler.
                    </p>
                </Col>
                <Col xs={12} md={4} className='text-center'>
                    <img className='mb-4' src={img39} style={{ filter: "grayscale(1)" }}/>
                    <h5 className='mt-4'><strong>Daily Analytics</strong></h5>
                    <p className='mt-2'  style={{color:"#A6A6A6"}}>
                        We always provide useful informatin to <br />  make it easier for you every day.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
