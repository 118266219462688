import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { IoStatsChart } from 'react-icons/io5';
import vektor from '../assest/img/vektor.svg';
import brand from '../assest/img/brand.svg';
import Slider from "react-slick";
import img from '../assest/img/rowImg.svg';
import img2 from '../assest/img/rowImg2.svg';
import img3 from '../assest/img/rowImg3.svg';
import img4 from '../assest/img/rowImg4.svg';
import img5 from '../assest/img/rowImg5.svg';

export default function Brand() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Container className="mb-5" id="home">
      <Row className="mt-5">
        <Col xs={12} md={6}>
          <h1 className="text-center text-md-start">Yeni Nesil Mesken Yönetimi</h1>
          <img className="d-block mx-auto mx-md-0 mt-2 mb-4" src={vektor} alt="vektor" />
          <p className="text-center text-md-start">
            Konutlar, İşyerleri, AVM’ler ihtiyacınız olan tüm yönetim özelliklerine Liviho ile sahip olabilirsiniz.
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start justify-content-center mt-4">
          <Button href='/#aboutUs' style={{ borderRadius: '40px', padding:"20px 30px 20px 30px" }}> İletişim </Button>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-5 mt-md-0 d-flex align-items-center justify-content-center">
          <div className="custom-card p-3 text-center">
            <p className="mb-1" style={{ color: '#A9A7B6' }}>Toplam Mesken</p>
            <div className="d-flex align-items-center justify-content-center">
              <h6 className="mb-0">1245</h6>
              <IoStatsChart className="ms-2" color='#52BD94' />
            </div>
          </div>
          <img src={brand} className="img-fluid" alt="brand" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Slider {...settings}>
            <div>
              <img src={img}  alt="First slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img2}  alt="Second slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img3}  alt="Third slide"  style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img4}  alt="Fourth slide" style={{ filter: "grayscale(1)" }}/>
            </div>
            <div>
              <img src={img5}  alt="Fifth slide" style={{ filter: "grayscale(1)" }}/>
            </div>
          </Slider>
        </Col>
      </Row>
    </Container>
  );
}
