import React from 'react'
import { Col, Row } from 'react-bootstrap'
import diff from '../assest/img/diff.svg'
import item from '../assest/img/item.svg'

export default function Diff() {
    return (
        <Row className='mt-5 custom-row'>
            <Row className='mt-5 custom-row'>
            <Col xs={12} md={6} className='d-flex flex-column justify-content mb-5 mb-md-0 mt-5 mt-md-0'>
                <h2 className='d-flex justify-content-center'>
                    <strong>Neler Farklı?</strong>
                </h2>
                <ul className='list-unstyled'>
                    <li className='d-flex justify-content-center align-items-center mt-4'>
                        <img src={item} alt="item"  />
                        <strong className='mt-1 mx-1'>Cüzdan Sistemi</strong>
                    </li>
                    <li className='d-flex justify-content-center align-items-center mt-4'>
                        <img src={item} alt="item" />
                        <strong className='mt-1 mx-2'>İşletme Sistemi</strong>
                    </li>
                    <li className='d-flex justify-content-center align-items-center mt-4' style={{  marginLeft:"35px" }}>
                        <img src={item} alt="item" />
                        <strong className='mt-1 mx-2'>Döngüsel Ödemeler</strong>
                    </li>
                    <li className='d-flex justify-content-center align-items-center mt-4' style={{  marginLeft:"10px" }}>
                        <img src={item} alt="item" />
                        <strong className='mt-1 mx-2'>Para Transferleri</strong>
                    </li>
                    <li className='d-flex justify-content-center align-items-center mt-4'  style={{  marginLeft:"-40px" }}>
                        <img src={item} alt="item" />
                        <strong className='mt-1 mx-2'>Bildirimler</strong>
                    </li>
                </ul>
            </Col>
            <Col xs={12} md={6} className='d-flex justify-content-center ' >
                <img src={diff} alt="diff" style={{ filter: "grayscale(1)", marginRight:"-210px" }}/>
            </Col>

        </Row>
            
        </Row>
    )
}
