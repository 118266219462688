import Brand from "./components/Brand";
import Diff from "./components/Diff";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Header from "./components/Header";



function App() {
  return (
    <div className="App">
      <Header/>
      
      <div className="brand">
        <Brand/>
        </div>
        <div className="features">
          <Features/>
        </div>
        <div className="diff">
          <Diff/>
        </div>
        <div className="footer">
          <Footer/>
        </div>
      
      
    </div>
  );
}

export default App;
